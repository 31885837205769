import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { LongButton } from "components/Button";
import CenterFlexBox from "components/CenterFlexBox";
import { useNavigate } from "react-router";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { testimonials } from './constant';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw", overflow: "hidden", height: "fit-content",
    [theme.breakpoints.down("md")]: {
      height: "fit-content", margin: "24px 0px", width: "100vw",
    }
  },
  container: {
    overflow: "hidden", backgroundColor: "black", display: "flex",
    flexDirection: "column", alignItems: "flex-start",
    [theme.breakpoints.down('md')]: { borderRadius: 0, },
  },
  testimonial: {
    borderRadius: 8,
    width: "fit-content",
    height: 250,
    backgroundColor: "white",
    maxWidth: 450,
    padding: 32,
    [theme.breakpoints.down(725)]: {
      maxWidth: "calc(100vw - 48px)", minWidth: "calc(100vw - 48px)",
      margin: "0 16px", padding: 24, minHeight: 180, height: 180,
    }
  },
  imageContainer: {
    width: 80, minWidth: 80, height: 80, minHeight: 80, marginRight: "15px",
    borderRadius: "50%", border: "1px solid grey", overflow: "hidden",
    display: "flex", justifyContent: "center", alignItems: "center",
    [theme.breakpoints.down(725)]: {
      minWidth: 40, minHeight: 40, height: 40, width: 40, margin: "0 1em 0 0"
    }
  },
  authorContainer: {
    height: "40%", display: "flex", alignItems: "flex-start",
    position: "relative",
    [theme.breakpoints.down(725)]: { height: "unset" }
  },
  socialIcon: {
    position: "absolute", top: 0, right: -8, fontSize: 48,
    [theme.breakpoints.down(725)]: {
      fontSize: 30, top: -16, right: -16,
    }
  },
}));

function Testimonial({ index, title, subtitle, imageUrl, testimonial, href }) {
  const classes = useStyles();

  return (
    <Box
      id={`testimonial-${index}`} className={classes.testimonial}
    >
      <Box className={classes.authorContainer}>
        <Box className={classes.imageContainer}>
          <img
            src={imageUrl} alt="title" width="100%" height="100%;"
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Box alignSelf="flex-start" flexGrow={1}>
          <Typography
            fontSize={"2vh"} letterSpacing={0} fontFamily='Inter'
            fontStyle="normal"
          >
            {title}
          </Typography>
          <Typography
            color="primary" fontWeight="light" fontSize={"1.8vh"}
            fontFamily='Inter' fontStyle="normal"
          >
            {subtitle}
          </Typography>
        </Box>
        {(href.length) ? (<Box
          className={classes.socialIcon}
          component="a" href={href} target="_blank" rel="noreferrer"
        >
          <LinkedInIcon color="primary" fontSize="inherit" />
        </Box>) : ""}
      </Box>
      <Box height="40%" maxHeight={"60%"} mt={4}>
        <Typography fontWeight="light" variant="body1" fontSize="1.6vh">
          "{testimonial?.substring(0, 350)}{testimonial?.length > 350 && "..."}"
        </Typography>
      </Box>
    </Box>
  );
}

function Testimonials() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <Box className={classes.container} id="testimonials">
        <Box
          pl={4} pt={4} width="100%" display="flex" justifyContent="center"
          flexDirection='column'
        >
          <Typography
            color="white" fontWeight={"bold"} fontSize={"2.8vmax"} fontFamily='Montserrat'
            lineHeight="3.5vmax" variant="h1" style={{ textAlign: 'center' }}
          >
            Empower Your Career with&nbsp;
            <span style={{ color: '#49B6FF' }}>Languify</span>
          </Typography> <br />
          <Typography
            color="white" fontWeight={"bold"} fontSize={"1.8vmax"} lineHeight={"2.5vmax"}
            variant="h1" style={{ textAlign: 'center' }}
          >
            Real Student Testimonials on Finding Job Opportunities,
            Studying Abroad, and Upskilling
          </Typography>
        </Box>
        <Box my={4} mx="auto" width="95vw">
          <Swiper
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={"auto"}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index} style={{ width: 'fit-content' }}>
                <Testimonial {...testimonial} index={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      <CenterFlexBox>
        <LongButton onClick={() => navigate('/signup')} />
      </CenterFlexBox>
    </Box>
  );
}

export default Testimonials;
import React from "react";
import shallow from "zustand/shallow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useStore from "../store";
import { Chip } from "@mui/material";

const QuestionTypeTags = {
    "mcq": "MCQ",
    "scq": "MCQ - Single Correct",
    "spoken": "Verbal",
    "written": "Written",
};

export function Progress({ questions, currentQuestion, ...props }) {
    return (
        <div style={{ display: "flex" }} {...props}>
            {questions.map((q, i) => (
                <div
                    key={i}
                    style={{
                        height: 4,
                        flexGrow: 1,
                        marginLeft: 2,
                        marginRight: 2,
                        borderRadius: 2,
                        backgroundColor:
                            i < currentQuestion ? "#235598" : "#23559830",
                    }}
                />
            ))}
        </div>
    );
}
function Head() {
    const questions = useStore(state => state.questions, shallow);
    const currentQuestion = useStore(state => state.currentQuestion, shallow);
    const totalQuestions = React.useMemo(() => questions?.length, [questions]);

    const question = questions[currentQuestion];
    return (
        <Box mb={1}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
            >
                <Typography
                    fontSize={20}
                    fontWeight={500}
                    fontFamily="Inter"
                    fontStyle="normal"
                >
                    {currentQuestion + 1}/{totalQuestions}
                </Typography>
                <Chip
                    label={QuestionTypeTags?.[question?.type]}
                    style={{
                        color: "#02569D",
                        backgroundColor: '#A6CEE3',
                        fontWeight: 600,
                        fontSize: '14px'
                    }}
                />
            </Box>
            <Progress questions={questions} currentQuestion={currentQuestion} />
        </Box>
    );
}

export default Head;
import Client from "./_client";

export const getTemplateQuestionsById = async (id) => {
  const result = await new Client({
    path: `/sepTemplate/${id}/questions`,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get interview attempt. Please try again!"
    );
  }

  return result?.data;
}

export async function subscribeToAudioLevel(listener) {
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
    });

    const audioContext = new AudioContext();

    // console.log('DEBUG::subscribeToAudioLevel');
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        if (!isNaN(average)) listener(Math.round(average));
    };

    return () => {
        scriptProcessor.disconnect();
        stream.getAudioTracks()?.forEach(track => track.stop());
    }
}
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { copyToClipboard } from 'utils';

import React from 'react';
import Button from '@mui/material/Button';
import CopiedIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';

const useStyles = makeStyles({
  root: {
    borderRadius: "6px",
    cursor: 'pointer',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: "16px",
    textTransform: "none",
    background: "#02569D",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
    color: "white",
  },
  darkButton: {
    minWidth: "fit-content",
    backgroundColor: '#02569D',
    textTransform: "none",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#034C8A",
    },
    "&:disabled, [disabled]": {
      backgroundColor: "#011F38",
      color: "#677988",
    },
  },
});

export function DarkButton(props) {
  const classes = useStyles();

  return (
    <Button
      className={classes.darkButton}
      {...props}
    />
  );
}

export function CopyButton({
  content, title = 'Copy', textRef, iconProps = {}, ...props
}) {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    if (copied) return;

    copyToClipboard(content, textRef);

    setCopied(true);

    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <IconButton onClick={handleCopy} title={title} {...props}>
      {copied ? <CopiedIcon color='success' /> : <CopyIcon {...iconProps} />}
    </IconButton>
  )
}


export default function CustomButton(props) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.root}
      sx={{
        ...(props.sx ? props.sx : {}),
      }}
      {...props}
    />
  );
}
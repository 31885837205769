import React from 'react';
import { makeStyles } from "@mui/styles";
import MUITextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    background: "#F1F2F6",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "#F1F2F6",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      },
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#F1F2F6",
      borderRadius: "6px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        color: 'black',
      },
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 12,
      fontWeight: 500
    },
  },
  error: {
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        color: 'red',
      },
    },
  }
}));

export function TextField(props) {
  const classes = useStyles();

  return (
    <MUITextField
      className={classes.root}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        minWidth: 180,
        ...(props.sx ? { ...props.sx } : {}),
      }}
      InputLabelProps={{ style: { fontSize: "1rem", fontWeight: 200 } }}
      {...props}
    // InputProps={(props.error ? { className: classes.error, ...props.InputProps } : { ...props.InputProps })}
    />
  );
}

export default TextField;
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import MUIButton from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from 'components/Sidebar';
import ProductMenu from "components/ProductMenu";
import ProfileMenu from "./ProfileMenu";
import { Session } from "utils";

const useStyles = makeStyles(theme => ({
  header: {
    height: "80px",
    left: 0,
    top: 0,
    padding: "20px 0px",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    }
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1400,
    margin: "0 auto",
    padding: "0 54px",
    [theme.breakpoints.down("md")]: {
      width: "85%"
    },
    [theme.breakpoints.down("md")]: {
      width: "95%",
      padding: 0
    }
  },
  desktopElement: {
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  mobileElement: {
    display: "none",
    cursor: "pointer",
    height: "-webkit-fill-available",
    margin: "16px 32px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    }
  },
  downloadButtonStyle: {
    borderRadius: "8px",
    padding: "15px 30px"
  },
  signinButtonStyle: {
    color: "white",
    textTransform: "none",
    marginRight: "40px",
    height: "10%"
  },
  logoContainer: {
    cursor: "pointer",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
    },
  },
  logo: {
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(4),
    },
  }
}));

export const MenuItems = [
  { title: "About Us", link: "https://languify.in/aboutus" },
  { title: "Blog", link: "/#blogs" },
  { title: "FAQs", link: "/#faqs" }
  // {
  //   title: "Get In Touch", newPage: true,
  //   link: process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL || "/",
  // },
];

const Menu = ({ title, link, newTab }) => {
  return (
    <MUIButton
      color="default"
      LinkComponent="a"
      href={link}
      target={newTab ? "_blank" : "_self"}
      sx={{
        textDecoration: "none",
        textTransform: "none",
      }}
    >
      {title}
    </MUIButton>
  );
}


function Header({ menuItems }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleOpenSidebar = () => setSidebarOpen(true)

  const _menuItems = menuItems || MenuItems;

  return (
    <Box className={classes.header} >
      <Box className={classes.logoContainer}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={"https://assets.languify.in/images/lang-logo.svg"}
            alt="languify" width={150} className={classes.logo}
          />
        </Link>
      </Box>
      <Box
        flexGrow={1} display="flex" alignItems="center" gap={6}
        justifyContent="flex-end" className={classes.desktopElement}
      >
        <ProductMenu />
        {_menuItems.map((menu, index) => (
          <Menu
            key={index} title={menu.title} link={menu.link}
            newTab={menu.newPage}
          />
        ))}
        {Session.isLoggedIn() ? (
          <ProfileMenu />
        ) : (
          <>
            <MUIButton
              style={{ textTransform: "none" }}
              color="default"
              onClick={() => navigate('/login')}

            >
              Login
            </MUIButton>
            <MUIButton
              style={{ textTransform: "none" }}
              variant="contained"
              onClick={() => navigate('/signup')}
            >
              Sign Up
            </MUIButton>
          </>
        )}
      </Box>

      {!sidebarOpen && (
        <MenuIcon
          className={classes.mobileElement}
          fontSize="large"
          color="default"
          style={{ margin: 0 }}
          onClick={handleOpenSidebar}
        />
      )}
      {sidebarOpen && <Sidebar setSidebarOpen={setSidebarOpen} />}
    </Box>
  );
}

export default Header;
import Client from "./_client";

export const getCertificate = async (id) => {
  const result = await new Client({ path: "/certificate/".concat(id) }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get your certificate. Please try again."
    );
  }

  return result?.data;
};
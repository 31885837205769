import { createTheme } from '@mui/material/styles';

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#02569D',
            light: '#338dd6',
            dark: '#07385e',
        },
        subprimary: { main: "#2DABFF" },
        secondary: {
            main: '#f50057',
        },
        error: {
            main: '#f55446',
        },
        divider: 'rgba(133, 140, 144, 1)',
        success: {
            main: '#35e9a8',
        },
        default: { main: '#ffffff' },
        blue: { main: "#2B45BA", dark: "#334fce" },
        purple: { main: "#8E0CD5", dark: "#760cb0" },
        orange: { main: "#FC9163", dark: "#d77a53" },
        grey: { main: "#7E88B0", dark: "#6a749a" },
        green: { main: "#07AD78", dark: "#0B9C60e2" },
        yellow: { main: "#F3B963", dark: "#d19d51" },
    },
    typography: {
        fontFamily: "Inter, sans-serif",
        h1: {
            fontWeight: 700,
            fontStyle: 'normal'
        },
        h2: {
            fontWeight: 700,
            fontStyle: 'normal'
        },
        h3: {
            fontWeight: 700,
            fontStyle: 'normal'
        },
        h4: {
            fontWeight: 600,
            fontStyle: 'normal'
        },
        h6: {
            fontWeight: 700,
            fontStyle: 'normal'
        },
        body1: {//text_x_large
            fontWeight: 400,
            fontStyle: 'normal'
        },
        subtitle1: {//body text large
            fontWeight: 400,
            fontStyle: 'normal'
        },
        body2: {//body text 
            fontWeight: 400,
            fontStyle: 'normal'
        },
        caption: {
            fontWeight: 400,
            fontStyle: 'normal'
        },
    },
};

const theme = createTheme(themeOptions);

export default theme;
import React from 'react';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import MUIAutocomplete, { autocompleteClasses }
    from '@mui/material/Autocomplete';

import TextField from 'components/TextField';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.paper}`]: {
        marginTop: "0px",
        borderRadius: "8px",
        backgroundColor: "#E4E3E8",
        fontSize: 11,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: "#E4E3E8",
        fontSize: 11,
        padding: "0px 14px",
        [`& .${autocompleteClasses.option}`]: {
            borderBottom: "0.5px solid #646464",
            fontSize: 11,
            margin: "10px 0px",
            "&:hover": {
                backgroundColor: "transparent",
                borderBottom: "1px solid #02569D"
            },
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: "transparent",
            },
        }
    },
});

function AutoComplete({
    defaultValue,
    placeholder,
    id,
    name,
    error,
    options = [],
    onSelect = () => { },
    onInput = (e) => { },
    containerProps = {},
    ...props
}) {

    return (
        <Box {...containerProps}>
            <MUIAutocomplete
                size="small"
                options={options}
                onChange={onSelect}
                PopperComponent={StyledPopper}
                renderInput={(params) =>
                    <TextField
                        error={error}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: name || 'none',
                        }}

                        id={id}
                        name={name}
                        size="small"
                        placeholder={placeholder}
                        onInput={onInput}
                        sx={{ minWidth: 80 }}
                    />
                }
                {...props}
            />
        </Box>);
}

export default AutoComplete;
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router";

import { LongButton } from "components/Button";
import CenterFlexBox from "components/CenterFlexBox";
import { Cards, CardWrapper } from "components/CardsEffect";

import abroadAndInternshipImg from "assets/images/one-stop-shop-1.jpg";
import jobAndInterviewImg from "assets/images/one-stop-shop-3.jpg";
import upskillingResoursesImg from "assets/images/one-stop-shop-2.jpg";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        border: '1px solid #8E0CD5', height: 46, borderRadius: 16,
        display: 'flex',
        width: "min(90vw, 1080px)",
    },
    tab: {
        width: 'fill-available', height: '100%', border: '0.5px solid #8E0CD5',
        fontFamily: 'Montserrat', fontSize: 14, fontWeight: 500, color: 'white',
        "&:hover": { cursor: 'pointer' },
        [theme.breakpoints.down('md')]: {
            fontSize: 10, textAlign: 'center', padding: 2, fontWeight: 600
        }
    },
    container: {
        display: 'flex',
        width: "min(90vw, 1080px)",
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse', alignItems: 'center'
        }
    },
    contentContainer: {
        width: "40%", flexDirection: 'column', gap: 30, justifyContent: 'center',
        flexShrink: 1,
        [theme.breakpoints.down('md')]: {
            width: '70%', alignItems: 'center'
        }
    },
    title: {
        color: 'white', fontFamily: 'Montserrat', fontSize: 28, fontWeight: 600,
        [theme.breakpoints.down('md')]: {
            fontSize: 20, textAlign: 'center'
        }
    },
    details: {
        wordBreak: "break-word",
        color: 'white', fontFamily: 'Montserrat', fontSize: 20, fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            fontSize: 14, textAlign: 'center'
        }
    },
    btn: {
        backgroundColor: '#0B9C60', textTransform: 'uppercase',
        fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600, width: 'fit-content',
        "&:hover": { backgroundColor: '#089c60db' }
    },
    cardsContainer: {
        position: "relative",
        width: "50%",
        flexGrow: 1,
        height: "420px",
        maxHeight: "600px",
        padding: 0,
        display: "flex",
        justifyItems: "flex-end",
        margin: "16px auto",
        [theme.breakpoints.down("md")]: {
            width: "calc(50% + 180px)",
            height: "300px"
        }
    },
    card: {
        width: "250px",
        height: "250px",
        borderRadius: "24px",
        overflow: "hidden",
        boxShadow: "27px 0px 53px 28px #00000050",
        [theme.breakpoints.down("md")]: {
            borderRadius: "8px",
            width: "200px",
            height: "200px"
        }
    }
}));

const INITIAL_STATE = 0;

const FEATURES = [
    {
        label: 'Job & Internship Opportunities',
        title: <>Find the Perfect <br /> Job or Internship</>,
        details: 'Tell us your skills, get interview ready and impress top recruiters. Start your journey now',
        br: '16px 0px 0px 16px',
    },
    {
        label: 'Abroad Education',
        title: <>Study Abroad & <br /> Up Your Game</>,
        details: 'Prepare for IELTS/TOEFL exams, get counselling for application, LORs, Essays and many more.',
        br: '',
    },
    {
        label: 'Career Upskillng',
        title: <>Best Upskilling <br /> Resources</>,
        details: 'Empower yourself with best resources for industry in-demand skills by upskilling.',
        br: '0px 16px 16px 0px',
    },
];

export default function OneStopShop() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [active, setActive] = React.useState(INITIAL_STATE);
    const [controlledSwiper, setControlledSwiper] = React.useState(null);

    const handleClick = (index) => {
        setActive(index);
        controlledSwiper?.slideTo(index);
    }

    return (

        <Box display='flex' flexDirection='column' alignItems='center'  >
            <Typography color="white" fontWeight={"bold"} fontSize={"2.8vmax"}
                fontFamily='Montserrat' lineHeight="3.5vmax" variant="h1"
                style={{ textAlign: 'center' }}>
                <span style={{ color: '#49B6FF' }}>
                    Languify&nbsp;
                </span>
                is your One-Stop Shop for
            </Typography>

            <br /><br />

            <Box className={classes.tabContainer}>
                {FEATURES.map(({ label, br }, index) => (
                    <CenterFlexBox
                        key={index}
                        className={classes.tab}
                        borderRadius={br}
                        backgroundColor={(active === index) ? '#8E0CD5' : 'black'}
                        onClick={() => handleClick(index)}
                    >
                        {label}
                    </CenterFlexBox>
                ))}
            </Box>

            <br />

            <Box className={classes.container}>
                <Box className={classes.contentContainer} display={'flex'}>
                    <Typography className={classes.title}>
                        {FEATURES[active].title}
                    </Typography>
                    <Typography className={classes.details}>
                        {FEATURES[active].details}
                    </Typography>
                    <LongButton
                        className={classes.btn}
                        variant='contained'
                        onClick={() => navigate('/signup')}
                    >
                        Discover Your Path
                    </LongButton>
                </Box>

                <Box className={classes.cardsContainer}>
                    <Cards onController={setControlledSwiper}>
                        {[
                            jobAndInterviewImg,
                            abroadAndInternshipImg,
                            upskillingResoursesImg
                        ].map((imageSrc, index) => (
                            <CardWrapper key={index} className={classes.card}>
                                <img
                                    src={imageSrc}
                                    alt={index}
                                    width="100%"
                                    height="auto"
                                />
                            </CardWrapper>
                        ))}
                    </Cards>
                </Box>
            </Box>

        </Box>
    );
}
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const SnackbarContext = React.createContext({
  _open: false,
  _message: "",
  _variant: "",
  _autoHide: true,
  _setOpen: (value) => { },
  _setMessage: (value) => { },
  _setVariant: (value) => { },
  _setAutoHide: (value) => { }
});

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackbar = ({ onClose, autoHide, ...props }) => {
  return (
    <Snackbar
      open={props.open}
      {...(autoHide ? { autoHideDuration: 3000 } : {})}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...props}
    >
      <Alert onClose={onClose} severity={props.variant} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export const SnackbarProvider = ({ children }) => {
  const [_open, _setOpen] = React.useState(false);
  const [_message, _setMessage] = React.useState("");
  const [_variant, _setVariant] = React.useState("");
  const [_autoHide, _setAutoHide] = React.useState(true);

  const handleClose = () => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  };

  return (
    <SnackbarContext.Provider
      value={
        {
          _open,
          _setOpen,
          _message,
          _setMessage,
          _variant,
          _setVariant,
          _autoHide,
          _setAutoHide,
        }
      }
    >
      <CustomSnackbar
        open={_open}
        message={_message}
        variant={_variant}
        onClose={handleClose}
        autoHide={_autoHide}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { _setOpen, _setMessage, _setVariant, _setAutoHide } = React.useContext(SnackbarContext);

  const showSnackbar = React.useCallback(
    (_message = "", _variant = "success", _autoHide = true) => {
      if (_message) {
        _setVariant(_variant);
        _setMessage(_message);
        _setAutoHide(_autoHide);
        _setOpen(true);
      }
    }, [_setOpen, _setMessage, _setVariant, _setAutoHide]);

  const hideSnackbar = () => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  };

  const error = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'error', !disableAutoHide);
  }, [showSnackbar]);

  const success = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'success', !disableAutoHide);
  }, [showSnackbar]);

  const warn = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'warning', !disableAutoHide);
  }, [showSnackbar]);

  const info = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'info', !disableAutoHide);
  }, [showSnackbar]);


  return { showSnackbar, hideSnackbar, error, success, warn, info };
};

export default CustomSnackbar;
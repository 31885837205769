export const checkPermission = async (name) => {
    return await new Promise((resolve, reject) => {
        if (navigator?.permissions && navigator?.permissions?.query) {
            navigator.permissions.query(
                { name }
            ).then(function (permissionStatus) {
                if (permissionStatus.state === "granted") resolve(true);
                else resolve(false);
            }).catch(err => {
                console.error(err);
                resolve(false);
            });
        } else {
            askPermission({ video: false, audio: true })
                .then(resolve)
                .catch(reject);
        }
    });
};

export const askPermission = async (permission) => {
    return await new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia(permission).then(stream => {
            resolve(true);
        }).catch(err => {
            console.error(err);
            resolve(false);
        });
    });
}
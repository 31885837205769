import RightIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { LightButton } from 'components/Button';
import { Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { blogs } from "./constant";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400, borderRadius: 8, padding: "24px 24px 0px 24px",
    height: 320, margin: '0px 30px',
    marginRight: 16, flexShrink: 0, [theme.breakpoints.down("md")]: {
      maxWidth: '85vw'
    },
  },
  overflow: {
    "--max-lines": 3,
    textAlign: "justify",
    position: "relative",
    overflow: "hidden",
    maxHeight: "60px",
    paddingRight: "1rem",
    "&::before": {
      position: "absolute",
      content: '"..."',
      bottom: 0,
      right: 0,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      width: "1rem",
      height: "1rem",
      background: "white",
    }
  }
}));

function BlogCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia
        component="img" image={props.imageUrl} alt={props.title}
        style={{ borderRadius: 8, width: '100%', height: '100px' }}
      />
      <CardContent>
        <Typography
          gutterBottom component="div" fontWeight={600} fontSize={14}
        >
          {props.title}
        </Typography>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="body2" color="text.secondary"
            className={classes.overflow}
          >
            {props.summary}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <LightButton
          size="small" color="primary" LinkComponent={"a"} href={props.blogUrl}
          target="_blank" rel="noreferrer"
          endIcon={<RightIcon color="primary" />}
          style={{ fontWeight: 600, fontSize: 13, padding: '8px 16px' }}
        >
          Read More
        </LightButton>
      </CardActions>
    </Card>
  );
}

function Blogs(props) {
  return (
    <Box backgroundColor="black" px={2} pt={5} pb={8} width="100%" id="blogs">
      <Typography
        color="white" mb={5} ml={2} fontWeight="bold" fontSize="2.8vmax"
        lineHeight="3.5vmax" variant="h1" textAlign='center' fontFamily='Montserrat'
      >
        Get started with&nbsp;
        <span style={{ color: '#49B6FF' }}>Languify</span>
      </Typography>
      <Box my={4} mx="auto" width="95vw">
        <Swiper
          navigation={true}
          mousewheel={true}
          direction='horizontal'
          spaceBetween={2}
          slidesPerView={"auto"}
          modules={[Mousewheel, Navigation]}
        >
          {blogs.map((blog, i) => (
            <SwiperSlide key={i} style={{ width: 'fit-content' }}>
              <BlogCard key={i} {...blog} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
}

export default Blogs;
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { LongButton } from "components/Button";
import { FreebiesCarousal } from "components/Carousal";
import { gtag } from "ga-gtag";
import { useNavigate, useParams } from "react-router-dom";

import Announcement from "components/Annoucement";
import Footer from "components/Footer";
import ResumeHeader from "components/Header";
import HorizontalScrollWrapper from "components/HorizontalScrollWrapper";
import Blogs from "./Blogs";
import Faq from "./Faq";
import OneStopShop from "./OneStopShop";
import Testimonials from "./Testimonials";

import { Partners } from "components/constants";
import { getUserNameByCode, incrementUsageByCode } from "services/referralService";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh', width: 'auto', overflowX: 'hidden',
        backgroundSize: 'cover', backgroundColor: 'black',
        backgroundImage: 'url(https://assets.languify.in/images/dark-background.jpeg)',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        }
    },
    main: {
        height: 'calc(95% - 80px)', display: 'flex', alignItems: 'center',
        justifyContent: 'space-between', flexDirection: 'column', zIndex: -1,
        [theme.breakpoints.down('sm')]: { gap: 30 },
    },
    contentContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0
    },
    title: {
        fontFamily: 'Montserrat', fontSize: 40, fontWeight: 600, color: 'white',
        textAlign: 'center', marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            marginTop: 10, fontSize: 20,
        }
    },
    details: {
        fontFamily: 'Montserrat', fontSize: 16, fontWeight: 500, color: 'white',
        maxWidth: 650, textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12, textAlign: 'center',
        }
    },
    trustedBy: {
        fontFamily: 'Montserrat', fontSize: 16, fontWeight: 600, color: 'white',
        textAlign: 'center', marginBottom: 4,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14, textAlign: 'center',
        }
    },
    partnersImg: {
        width: '120px',
        [theme.breakpoints.down('sm')]: { width: '80px', }
    }
}));

export default function ResumeHome() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { referralCode } = useParams();
    const [referrerName, setReferrerName] = React.useState(null);

    const handleClick = () => {
        gtag('event', 'get_freebies_clicked', {});

        navigate('/signup');
    }

    React.useEffect(() => {
        if (referralCode) {
            localStorage.setItem('rc', referralCode);

            getUserNameByCode(referralCode)
                .then(setReferrerName)
                .catch(console.error);

            incrementUsageByCode(referralCode)
                .then(console.log)
                .catch(console.error);
        }
    }, [referralCode]);

    return (
        <>
            <Box
                overflow='hidden'
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                backgroundColor='black'
            >
                <Announcement />
                <Box className={classes.container}>
                    <ResumeHeader />
                    <Box className={classes.main}>
                        <Box className={classes.contentContainer} >
                            {/*  {referrerName && (
                                <Typography className={classes.title}>
                                    {referrerName}
                                </Typography>
                            )} */}
                            <Typography className={classes.title}>
                                Get&nbsp;
                                <span style={{ color: '#49B6FF' }}>
                                    curated career resources&nbsp;
                                </span>
                                for your&nbsp;
                                <span style={{ color: '#49B6FF' }}>Dream Job!</span>
                            </Typography>
                            <Typography className={classes.details}>
                                Take our 3-step short quiz and we will give you a
                                to-the-point list of resources that will take your
                                preparation closer to your goal.
                            </Typography>
                        </Box>

                        <LongButton onClick={handleClick} />

                        <FreebiesCarousal />

                        <Box width='100%' >
                            <Typography className={classes.trustedBy}>
                                Trusted by 50+ Businesses
                            </Typography>
                            <HorizontalScrollWrapper sx={{ background: 'white' }}>
                                {Partners.map(({ imagePath, url, name }, i) => (
                                    <Box
                                        key={i}
                                        display='flex'
                                        alignItems='center'
                                        mx={1}
                                    >
                                        <img
                                            src={imagePath} alt={name}
                                            className={classes.partnersImg}
                                        />
                                    </Box>
                                ))}
                            </HorizontalScrollWrapper>
                        </Box>
                    </Box>

                </Box>

                <br /><br /> <br /><br />

                <OneStopShop />

                <br /><br /><br />

                <Blogs />

                <Testimonials />

                <br /><br /> <br /><br />

                <Faq />

                <Footer />

                <Typography
                    variant="caption"
                    textAlign="center"
                    sx={{
                        backgroundColor: "primary.dark",
                        color: "white",
                        px: "10vw", py: 2
                    }}
                >
                    *All trademarks are the property of their respective owners.TOEFL®
                    is a registered trademark of Educational Testing Service (ETS®).
                    Languify is not endorsed or approved by ETS®.
                    IELTS™ is a registered trademark of University of Cambridge ESOL,
                    the British Council, and IDP Education Australia.
                </Typography>

            </Box>
        </>
    );
}
export const blogs = [
    {
        title: "How to score 110 in TOEFL? Preparation strategy",
        imageUrl: "https://peerboard.com/1935902282/file/e0b41680-2844-47e3-a5a9-c1e8047ef829_1600_1000",
        blogUrl: "https://peerboard.com/1935902282/post/490240472",
        summary: "Test of English as a Foreign Language, or TOEFL for short, is precisely what it sounds like - an exam that tests your fluency in English by testing four specific skills: reading, writing, speaking and listening. Business and technical schools for masters abroad require these scores, as classes are taught in English, so above-average proficiency is required. A score of anything around 105+ is considered to be a very good score. Let's begin with the strategy.",
    },
    {
        title: "What is IELTS ?",
        imageUrl: "https://www.pngitem.com/pimgs/m/696-6966619_ielts-logo-png-transparent-png-download.png",
        blogUrl: "https://peerboard.com/1935902282/post/949147033",
        summary: "IELTS stands for International English Language Testing System. This test is designed for those people who want to study in English Speaking Countries like Australia, New Zealand, the US, the UK, and Canada.",
    },
    {
        title: "All about the TOEFL test.",
        imageUrl: "https://peerboard.com/1935902282/file/7c602998-0f6b-4292-a7a2-1c38b1e35284_1600_311",
        blogUrl: "https://peerboard.com/1935902282/post/2020921557",
        summary: "The TOEFL test measures an individual's ability to combine listening, reading, speaking, and writing in the classroom, where it matters most. By partnering with an English-speaking institution, you can gain access to millions of eligible students. ETS conducts the TOEFL exam, the full name of which is the Test of English as a Foreign Language.",
    },
    {
        title: "Importance of TOEFL",
        imageUrl: "https://peerboard.com/1935902282/file/582ad4dc-348f-441a-b0a0-4d3dbc3852f4_972_533",
        blogUrl: "https://peerboard.com/1935902282/post/1628037849",
        summary: "In TOEFL, an individual is assessed on how well they communicate in English. TOEFL scores are accepted by English-speaking countries across the globe, making it crucial for employment, study, and immigration. Universities maintain minimum TOEFL score requirements for admission, so it is very important to have a decent score when you are studying abroad.",
    },
    {
        title: "Preparation Strategy for IELTS (How to score high grades)",
        imageUrl: "https://peerboard.com/1935902282/file/03161e6d-f6fb-4116-8c9d-bdaaac90c073_970_531",
        blogUrl: "https://peerboard.com/1935902282/post/498131822",
        summary: "Rule one is to understand the different Test Formats. IELTS has two types of papers; one for academic purposes and one for General tests for Migration purposes. Which are further divided into 4 categories of questions namely; Listening,  Reading, Speaking, and Writing.",
    },
    {
        title: "TOEFL preparation tips",
        blogUrl: "https://peerboard.com/1935902282/post/1764852477",
        imageUrl: "https://peerboard.com/1935902282/file/6c5a1cd1-f310-4dc5-8bfc-c4b136aa7b61_885_480",
        summary: "Since there are so many TOEFL tips and strategies out there, it can be difficult to figure out how to approach them. To help you succeed on the TOEFL, we have compiled a list of the six most important tips you need to know.",
    },
    {
        title: "Common Mistakes made in IELTS",
        blogUrl: "https://peerboard.com/1935902282/post/1278800647",
        imageUrl: "https://peerboard.com/1935902282/file/d0b319f4-6f0f-409d-8763-ba175b5c462a_836_370",
        summary: "Not Familiarising with the options in which we can give the IELTS test.The IELTS test is both computer- based and written.You need to know which you can perform better and practice further for that.The number of Questions and Questions are actually the same in both cases.",
    },
    {
        title: "Importance of TOEFL Exam in Top Universities",
        blogUrl: "https://peerboard.com/1935902282/post/1046986076",
        imageUrl: "https://peerboard.com/1935902282/file/dc674eaa-b39a-4c9a-8cd4-e4b980d3aa00_483_473",
        summary: "We are grateful to live in such a blessed world. Students today are not limited to the educational opportunities offered by their town, city, state, or country. Undergraduate and postgraduate education abroad is becoming more and more popular. Studying abroad has become easier than ever thanks to the internet.",
    }
    ,
    {
        title: "TOEFL Exam Pattern: Test Structure and Scoring",
        imageUrl: "https://peerboard.com/1935902282/file/0d98d9c6-35b0-410d-b1d2-302d1bb166bd_704_474",
        blogUrl: "https://peerboard.com/1935902282/post/1330306622",
        summary: "Taking into account the objective and keeping in mind the goal of evaluating the taker, the TOEFL syllabus is designed. There are four skill areas measured by the test questions and tasks: reading, listening, speaking, and writing.",
    }
    , {
        title: "TOEFL Test prepration",
        imageUrl: "https://peerboard.com/1935902282/file/be373335-4378-45a8-9b4d-d39882d03469_794_527",
        blogUrl: "https://peerboard.com/1935902282/post/388288338",
        summary: "To study abroad, start a new job in an English-speaking environment, or to immigrate to an English-speaking country, you may need to pass the TOEFL test. In over 160 countries, TOEFL scores are accepted by more than 11,500 institutions. There is no doubt that it is one of the most popular language tests on the planet. The TOEFL is taken by an estimated 2.3 million students each year.",
    }
    , {
        title: "What is a good TOEFL score in 2022 ?",
        imageUrl: "https://peerboard.com/1935902282/file/43f1e453-76d1-44ed-bf3d-541d37503a35_877_533",
        blogUrl: "https://peerboard.com/1935902282/post/1987440506",
        summary: "Most famous universities overseas have strict guidelines regarding TOEFL scores, which students must adhere to without second thoughts. TOEFL is an English proficiency test intended for those interested in pursuing careers in the United States, the United Kingdom, Canada, Australia, or New Zealand who do not speak English as their first language.",
    }
    , {
        title: "What does your TOEFL score reflect ?",
        imageUrl: "https://peerboard.com/1935902282/file/37400892-aeb7-47b7-8251-e5e4fa2025e9_851_545",
        blogUrl: "https://peerboard.com/1935902282/post/1763023161",
        summary: "In English-speaking countries, students who do not speak English natively must prove their proficiency in the language. In addition to the IELTS, the TOEFL is widely accepted as a means of demonstrating academic English proficiency. Certain types of students may be exempt from language requirements.",
    }
    , {
        title: "How to Avoid Common TOEFL mistakes ?",
        blogUrl: "https://peerboard.com/1935902282/post/1180272800",
        imageUrl: "https://peerboard.com/1935902282/file/d39683ee-67e0-4e54-859a-abd530ad3f40_971_544",
        summary: "Is TOEFL iBT on your schedule? Perhaps you've already read about all the tips and tricks for studying for and acing the exam. When it comes to the TOEFL iBT, have you ever considered some of the most common mistakes people make?",
    }
    , {
        title: "Significance of the TOEFL Exam",
        imageUrl: "https://peerboard.com/1935902282/file/d4e6760a-9d58-440d-a431-3fcda250c696_758_544",
        blogUrl: "https://peerboard.com/1935902282/post/333297332",
        summary: "To demonstrate your English proficiency, take the TOEFL iBT test and universities will notice you. The TOEFL iBT test is the world's most widely accepted English-language test for study, work, and immigration. It is accepted by more than 11,500 universities and institutions in more than 160 countries.",
    },
];

export const testimonials = [
    {
        title: "Vishal Saini",
        subtitle: "TOEFL Score : 107",
        imageUrl: "https://assets.languify.in/images/testimonial-vishal-saini.jpeg",
        href: "https://www.linkedin.com/in/vishal-saini-8927461b9/",
        testimonial: "I really love the feedback of individual questions as they help me in overall."
    },
    {
        title: "Tanay gandhi",
        subtitle: "TOEFL Score: 105",
        imageUrl: "https://assets.languify.in/images/person.svg",
        href: "https://www.linkedin.com/in/tanaygandhi306",
        testimonial: "I would recomend this tests to everybody who are short on time & want to make use of this toefl simulated test's."
    },
    {
        title: "Sayli",
        subtitle: "TOEFL Score: 112",
        imageUrl: "https://assets.languify.in/images/testimonial-sayli-pednekar.jpeg",
        href: "https://www.linkedin.com/in/sayli-pednekar-2402/",
        testimonial: "Languify's Unlimited mock practises helped me in increasing my score by several points."
    },
    {
        title: "Shruti Patnaik",
        subtitle: "TOEFL Score: 100",
        imageUrl: "https://assets.languify.in/images/testimonial-shruti-patnaik.jpeg",
        href: "https://www.linkedin.com/in/shruti-patnaik-9925741a1",
        testimonial: "Languifys mock helped me to practise daily and increase my score."
    }
];

import * as Joi from "joi";

export const RegistrationSchema = Joi.object({
    name: Joi.string().min(3).max(30).required('Please provide your name'),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required('Please provide your email'),
    city: Joi.string().required("Please provide your city"),
    countryCode: Joi.string().required(),
    phone: Joi.string().required("Please provide phone number"),
    otp: Joi.string().min(6).max(6),
    password: Joi.string().optional()
}).options({ abortEarly: false });
import React from "react";

import "./styles.css";

export function CardWrapper({ children, slideId, priority, style = {}, className = '' }) {
  return (
    <div
      id={slideId}
      className={`${className} card-wrapper `}
      style={{
        ...style,
        transform: `perspective(100px) translate3d(-${15 * priority}%, 0, ${10 * priority
          }px)`,
        zIndex: Math.abs(priority + 1)
      }}
    >
      {children}
    </div>
  );
}

export function Cards({ children, onController }) {
  const [stack, setStack] = React.useState([]);

  const arrayChildren = React.Children.toArray(children);

  const removeAnimationClasses = () => {
    children.forEach((v, i) => {
      const slide = document.getElementById(`cards-card-${i}`);
      slide.classList.remove("bottom-card");
      slide.classList.remove("top-card");
    });
  };

  const addAnimationClass = (index, className) => {
    document
      .getElementById(`cards-card-${index}`)
      .classList.add(className);
  };

  const slideChanger = React.useCallback(
    (next) => () => {
      removeAnimationClasses();

      setStack((s) => {
        const newStack = [...s];

        if (next) {
          const top = newStack.shift();

          addAnimationClass(top, "bottom-card");

          newStack.push(top);
        } else {
          const bottom = newStack.pop();

          addAnimationClass(bottom, "top-card");

          newStack.unshift(bottom);
        }

        return newStack;
      });
    },
    []
  );

  const slideTo = React.useCallback((index) => {
    if (Number.isInteger(index)) {
      setStack((s) => {
        const newStack = [...s];

        while (newStack[0] !== index) {
          removeAnimationClasses();

          const top = newStack.shift();

          addAnimationClass(top, "bottom-card");

          newStack.push(top);
        }

        return newStack;
      });
    }
  },
    []
  );

  React.useEffect(() => {
    if (children.length) {
      setStack(children.map((x, i) => i));
      if (typeof onController === "function")
        onController({
          next: slideChanger(true),
          prev: slideChanger(false),
          slideTo
        });
    }
  }, []);

  return (
    <div className={"cards"}>
      {React.Children.map(arrayChildren, (child, index) => {
        const slideId = `cards-card-${index}`;
        const priority = stack.length - stack.indexOf(index);

        return React.cloneElement(child, { slideId, priority });
      })}
    </div>
  );
}

import Client from "./_client";

export const incrementUsageByCode = async (code) => {
  const result = await new Client({ path: `/referral/${code}` }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to increment the usage"
    );
  }

  return result?.data;
};

export const getUserNameByCode = async (code) => {
  const result = await new Client({ path: `/referral/${code}/user-name` }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to get the name"
    );
  }

  return result?.data;
};
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WrongBrowserImage from "assets/unsupported-browser.svg";

import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { CopyButton } from "components/CustomButton";
import { Session } from "utils";

const useStyles = makeStyles((theme) => ({
  container: { textAlign: 'center' },
  icon: { color: '#FFCC20', fontSize: '5rem', fontWeight: 800 },
  downloadButtons: {
    display: "flex", width: "100%", justifyContent: "center", gap: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: "column", justifyContent: "center", margin: "0px 16px",
      width: "calc(100% - 32px)", gap: 16,
    }
  }
}));

export function UnsupportedDeviceMessage({ message }) {
  const classes = useStyles();
  const linkRef = useRef();
  console.log(Session.user);
  return (
    <Box className={classes.container}>
      <img src={WrongBrowserImage} alt="WrongBrowserImage" height="280" />
      <Typography
        variant="h4" margin='auto' color='primary.main'
        fontSize={28} maxWidth={500}
      >
        Uh Oh!
      </Typography>
      <Typography
        variant="body2"
        margin='2em auto'
        fontSize={18}
        maxWidth={500}
      >
        {message}
      </Typography>

      {
        Session?.user?.role?.name === 'SEP_RESUME' && <> <Typography
          color="primary" component="a"
          href="https://sep.languify.in/congratulations"
          target={"_blank"}
          rel="referrer"
          variant="subtitle2"
          ref={linkRef}
        >
          https://sep.languify.in/congratulations
        </Typography>
          <CopyButton
            content={"https://sep.languify.in/congratulations"}
            title="Login URL"
            textRef={linkRef}
            iconProps={{ color: "primary" }}
          /></>
      }
      <br /><br />
      <Box className={classes.downloadButtons}>
        <Button
          LinkComponent="a"
          href="https://www.google.com/chrome/"
          target="_blank"
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none", px: 2 }}
        >
          Download Google Chrome
        </Button>
        <Button
          LinkComponent="a"
          href="https://www.microsoft.com/en-us/edge"
          target="_blank"
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none", px: 2 }}
        >
          Download Microsoft Edge
        </Button>
      </Box>
    </Box>
  );
}

const message = <>
  To take the assessment please open the URL in <br />Google Chrome or Microsoft Edge.
</>;

function UnsupportedBrowser() {
  return (
    <>
      <Box width='100%' display='flex' justifyContent='center' py={4}>
        <img
          src="https://assets.languify.in/images/lang-logo.svg"
          alt="Languify"
        />
      </Box>
      <UnsupportedDeviceMessage
        message={message}
      />
    </>
  );
}

export default UnsupportedBrowser;
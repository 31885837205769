import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: "transparent",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<img
            src="https://assets.languify.in/images/arrow-black-right.svg"
            alt="right-arrow"
            width={32}
            height={32}
            style={{
                fontSize: '3rem',
                mx: 3
            }}
        />}
        {...props}
    />
))(({ theme }) => ({
    borderBottom: "1px solid #9D9D9D",
    backgroundColor: "transparent",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    backgroundColor: "#5FBEFD",
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    color: "white"
}));

const useStyles = makeStyles(theme => ({
    faqsContainer: {
        backgroundColor: "#F9F9F9", padding: '0px 48px', borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
    }

}));

export default function CustomFaqs({ faqs = [], color, ...props }) {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(-1);

    const handleAccordianChange = (i) => (e, expanded) => {
        if (expanded) setExpanded(i);
        else setExpanded(-1);
    };
    return (
        <Box className={classes.faqsContainer} {...props}>
            {faqs.map((faq, index) => (
                <Accordion
                    key={index}
                    expanded={index === expanded}
                    onChange={handleAccordianChange(index)}
                    sx={{ width: "100%", textAlign: "left" }}
                >
                    <AccordionSummary>
                        <Typography
                            variant="body1" fontWeight={400} component="h6"
                            color={color || 'black'}
                        >
                            {faq.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            faq.answers.map((answer, index) => (
                                <Typography
                                    key={index} variant="subtitle1"
                                    color="white.main"
                                >
                                    {answer} <br />
                                </Typography>
                            ))
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
import { Navigate } from "react-router-dom";
import Session from "../utils/Session";

function PrivateRoute({ component: RouteComponent }) {
  const guestUser = JSON.parse(localStorage.getItem("isGuestUser"));

  return (
    Session.isLoggedIn() ? (
      <RouteComponent />
    ) : (
      <Navigate to={guestUser ? "/" : "/error"} />
    )
  );
}

export default PrivateRoute;
import Session from "../utils/Session";

const apiUrl = process.env.REACT_APP_API_URL;

export const analyseAnswer = async ({
  attempt,
  questionType,
  timeTaken = 0,
  transcript = "",
  audioBlob = null,
  serialNumber,
  answer = [""],
  question = "",
}) => {
  const formData = new FormData();

  formData.append("questionType", questionType);
  formData.append("attempt", attempt);
  formData.append("timeTaken", timeTaken);
  formData.append("serialNumber", serialNumber);
  formData.append("audio", audioBlob);
  formData.append("transcript", transcript);
  formData.set("answer", answer);
  formData.set("question", question);

  let result = await fetch(`${apiUrl}/analyse/sep`, {
    headers: {
      userId: Session.userId,
      "languify-application": "sep",
      "languify-access-token": Session.accessToken,
      "languify-session-id": localStorage.getItem("languifySessionId"),
    },
    method: "POST",
    body: formData,
  });

  if (!(result.ok || result.status === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to analyse the answer.");
  }

  result = await result.json();

  return result?.data;
};

export const analyseSpokenAnswer = ({
  attempt, questionType, serialNumber, timeTaken = 0, transcript = "",
  audioBlob = null, answer = [""], question = ""
},
  onEvent = () => { }
) => {
  const httpRequest = new XMLHttpRequest();
  const formData = new FormData();

  formData.append("questionType", questionType);
  formData.append("attempt", attempt);
  formData.append("timeTaken", timeTaken);
  formData.append("serialNumber", serialNumber);
  formData.append("transcript", transcript);
  formData.append("answer", answer);
  formData.append("question", question);

  formData.append("audio", audioBlob);

  httpRequest.upload.addEventListener('progress', (e) => {
    // console.log('Service::analyseAnswer:onprogress ->', e);
    const progress = Math.round((e.loaded / e.total) * 100);
    onEvent('progress', progress);
  });

  httpRequest.onload = (e) => {
    const response = JSON.parse(e?.target?.response || null);

    console.log("DEBUG::analyseSpokenAnswer-> Response: ", response);

    if (e?.target?.status !== 200) {
      onEvent('error', response?.data);
    } else if (response?.data?.isError) {
      onEvent('error', response?.data);
    } else {
      onEvent('analysed', response?.data);
    }
  };
  httpRequest.onabort = (e) => {
    // console.log('Service::analyseAnswer:onabort ->', e);
    onEvent('error', e);
  };
  httpRequest.onerror = (e) => {
    // console.log('Service::analyseAnswer:onerror ->', e);
    onEvent('error', e);
  };

  httpRequest.open('POST', `${apiUrl}/analyse/sep`, true);
  httpRequest.setRequestHeader('userId', Session.userId);
  httpRequest.setRequestHeader('languify-application', 'sep');
  httpRequest.setRequestHeader('languify-access-token', Session.accessToken);
  httpRequest.send(formData);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { analyseAnswer, analyseSpokenAnswer };

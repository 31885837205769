import React from "react";
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomFaqs from "components/CustomFaqs";
import Toggle from "components/Toggle";

const STUDENT_FAQs = [
    {
        question: "What kind of resources does your website offer for college students?",
        answers: [
            "Our website offers a wide range of resources for college students, including information on foreign education options, career upskilling opportunities, job and internship listings, and interview preparation tips."
        ],
    },
    {
        question: "How do you curate the resources available on your website?",
        answers: ["Our team of experts carefully researches and selects only the most relevant and reliable resources for college students. We prioritize resources that are accessible, practical, and tailored to the unique needs of students."],
    },
    {
        question: "Is the website only for students in a specific field or discipline?",
        answers: ["No, our website is designed to be inclusive and helpful for students from all disciplines and fields of study. We strive to provide a diverse range of resources to accommodate the varying aspirations of our users."],
    },
    {
        question: "Can I use your website if I am not a college student?",
        answers: ["Yes, our website is open to anyone who is interested in exploring the resources we have available. However, our focus is primarily on providing support and guidance to college students."]
    },
    {
        question: "How often is the information on your website updated?",
        answers: ["We are constantly updating and adding new resources to our website, to ensure that our users have access to the most up-to-date information and opportunities. We encourage users to check back frequently and stay informed about the latest developments in their area of interest."]
    },

];

const BUSINESS_FAQs = [
    {
        question: "What is the Languify lead-generation flow, and how does it work?",
        answers: ["The Languify lead-generation flow is a series of carefully designed questions that allow us to better understand the background, aspirations, and goals of our users. By collecting this information, we can curate a personalized list of resources that best suit their needs."]
    },
    {
        question: "What kind of resources are included in the lead-gen flow?",
        answers: ["Our lead-gen flow includes access to a range of resources, such as one-on-one career counseling with our partners, communication skill analysis and certification, an interview preparation tool called InPrep, guidance for students looking to study abroad, access to mock TOEFL/IELTS tests, and a supportive community of like-minded individuals."]
    },
    {
        question: "How does Languify ensure that the resources provided are tailored to each user's needs?",
        answers: ["By collecting information through the lead-gen flow, we are able to better understand each user's background, aspirations, and goals. This information is used to curate a personalized list of resources that best suit their needs. We are dedicated to providing our users with the most comprehensive and tailored resources possible."]
    },
    {
        question: "Can businesses use the Languify lead-gen flow?",
        answers: ["Yes, businesses can use the Languify lead-gen flow to help their employees improve their language and communication skills, and to achieve their career goals."]
    },
    {
        question: "How can businesses get started with the Languify lead-gen flow?",
        answers: ["To get started with the Languify lead-gen flow, businesses can visit our website and fill out the form with their information. We will then reach out to them to discuss how we can best assist them and their employees."]
    },
    {
        question: "Is Languify dedicated to improving and expanding its offerings?",
        answers: ["Yes, at Languify we are constantly working to improve and expand our offerings. We are dedicated to helping our users achieve their language and career goals, and we strive to provide them with the most comprehensive and tailored resources possible."]
    },
];

const useStyles = makeStyles(theme => ({
    parentContainer: {
        width: "90%", display: 'flex', flexDirection: 'column',
        margin: "1em auto 4em auto", alignItems: 'center',
        [theme.breakpoints.down(725)]: { marginBottom: "4em", width: "100%" }
    },
    faqsContainer: {
        backgroundColor: "#F9F9F9", padding: '0px 48px',
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
    }
}));

function Faq() {
    const classes = useStyles();
    const [faqs, setFaqs] = React.useState(STUDENT_FAQs);

    const handleChange = ({ id }) => {
        if (id === 'student') setFaqs(STUDENT_FAQs);
        else setFaqs(BUSINESS_FAQs);
    }

    const mobileView = window.innerWidth < 500;

    return (
        <Box id="faqs" className={classes.parentContainer}>
            <Typography
                ml={0} color="white" textAlign="center" fontWeight={600}
                fontSize="2.8vmax" fontFamily='Montserrat'
            >
                FAQ's
            </Typography>
            <br />
            <Toggle
                width={mobileView ? 200 : 400} height={30} br={4}
                options={[{ label: 'Students', id: 'student' }, { label: 'Businesses', id: 'business' }]}
                onChange={handleChange}
            />
            <br /> <br />
            <CustomFaqs faqs={faqs} />
        </Box>);
}

export default Faq;
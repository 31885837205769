import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  container: {
    background: "black",
    border: '1px solid #8E0CD5',
    minWidth: 100,
    height: 'fit-content',
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  slider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "50%",
    height: "100%",
    background: "#8E0CD5",
    position: "absolute",
    zIndex: 0,
    transition: "400ms ease-in-out",
    boxShadow: "0 0 4px 0px #8E0CD5",
    "&>:hover": {
      background: "#8E0CD5ee",
    }
  },
  content: {
    padding: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 50,
    width: "50%",
    background: "transparent",
    zIndex: 1,
    letterSpacing: 1,
    color: "#02569D",
    cursor: "pointer",
    transition: "400ms ease-in-out",
  }
});

function Toggle({ options = [], onChange = () => { }, width, height, br, ...props }) {
  const classes = useStyles();

  const [active, setActive] = React.useState(0);

  const handleToggle = () => setActive((active) => active === 0 ? 1 : 0);

  React.useEffect(() => {
    typeof onChange === 'function' && onChange(options[active]);
  }, [active]);

  return (
    <Box width={width} {...props} >
      <Box className={classes.container}
        sx={{ borderRadius: br ? br : 3 }}
        style={{ height: height }}
      >
        <Box className={classes.slider}
          sx={{ left: active ? "50%" : "0%", borderRadius: br ? br : 3 }}>
        </Box>
        <Box className={classes.content}
          onClick={handleToggle}
          style={{ color: "white" }}
        >
          <Typography variant="caption">
            {options?.[0]?.label || "Left"}
          </Typography>
        </Box>
        <Box className={classes.content}
          onClick={handleToggle}
          style={{ color: 'white' }}
        >
          <Typography variant="caption">
            {options?.[1]?.label || "Right"}
          </Typography>
        </Box>
      </Box >
    </Box >
  );
}

export default Toggle;
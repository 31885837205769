import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { MenuItems } from './Header';

const useStyles = makeStyles(theme => ({
    sidebar: {
        position: 'fixed',
        width: 280,
        height: '100vh',
        top: 0,
        right: 0,
        padding: '1em',
        backgroundColor: '#001929',
        boxShadow: '-0px 0 12px grey',
        zIndex: 1,
    },
    closeIcon: {
        float: 'right',
        fontSize: '2rem',
        color: '#2DABFF',
        margin: "12px 16px"
    },
    blueText: {
        color: '#2DABFF',
        marginTop: '1em'
    },
    signUpButton: {
        paddingLeft: 0,
    }
}));

function ProductItem({ url, children }) {
    return (
        <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => window.open(url, "_self")}
        >
            <Typography variant='h6' color='primary'>
                {children}
            </Typography>
        </ListItemButton>
    );
}

function Sidebar({ setSidebarOpen }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleCloseSidebar = () => {
        setSidebarOpen(false);
    }

    return (
        <Box className={classes.sidebar}>
            <CloseIcon
                onClick={handleCloseSidebar}
                className={classes.closeIcon} />
            <List sx={{ my: '3em', width: "100%" }}>
                {MenuItems.map(option => (
                    <ListItemButton key={option.id} >
                        <a
                            href={option.link}
                            style={{ textDecoration: 'none', width: "100%" }}
                        >
                            <Typography variant='h6' color='primary'>
                                {option.title}
                            </Typography>
                        </a>
                    </ListItemButton>
                ))}
                <ListItemButton onClick={() => setOpen(!open)} >
                    <Typography variant='h6' color='primary' mr={1}>
                        Products
                    </Typography>
                    {open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ProductItem url={process.env.REACT_APP_MOCK_URL}>
                            IELTS & TOEFL
                        </ProductItem>
                        <ProductItem url={process.env.REACT_APP_INPREP_URL}>
                            In-Prep
                        </ProductItem>
                        <ProductItem url={process.env.REACT_APP_EXTENSION_URL}>
                            Chrome Extension
                        </ProductItem>
                    </List>
                </Collapse>
            </List>
        </Box >
    );
}

export default Sidebar;

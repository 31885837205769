import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Freebies } from "./constants";
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  marqueeBox: {
    display: "flex", animation: "$marquee 40s linear infinite",
    [theme.breakpoints.up('lg')]: {
      animation: "$marquee 15s linear infinite",
    },
    "&:hover": { animationPlayState: 'paused' }
  },
  "@keyframes marquee": {
    "0%": {
      transform: "translateX(0%)",
    },
    "50%": {
      transform: "translateX(calc(-1956px + 100vw))",
    },
    "100%": {
      transform: "translateX(0%)",
    }
  },
  img: {
    width: 240,
    [theme.breakpoints.down('lg')]: {
      width: 240,
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
  },
  card: {
    minWidth: 300, mx: 1, background: 'white', textDecoration: 'none',
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    backgroundColor: 'transparent', flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 240,
    },
  }
}));


function FreebiesCarousalCard({ step }) {
  const classes = useStyles();

  return (
    <Card
      component="a" href={step.url} target="_blank" rel="noreferrer"
      className={classes.card}
    >
      <img src={step.imagePath} alt={step.name} className={classes.img}
      />

      <Typography
        color='white' fontFamily='Montserrat' fontSize={14}
        fontWeight={700} mt={2} textAlign='center'
        dangerouslySetInnerHTML={{
          __html: step.details
        }}
      >
      </Typography>
    </Card>
  );
}

export function FreebiesCarousal({ ...props }) {
  const classes = useStyles();

  return (
    <Box width="100%" {...props}>
      <Box className={classes.marqueeBox}>
        {Freebies.map((step, index) => (
          <FreebiesCarousalCard key={index} step={step} />
        ))}
      </Box>
    </Box >
  );
}
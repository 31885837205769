import React from "react";

import { useNavigateWithClient } from "hooks";
import { isBrowserSupported } from "utils";

function BrowserSupportChecker({ component: RouteComponent, componentProps = {} }) {
    const navigateWithClient = useNavigateWithClient();

    React.useEffect(() => {
        (async () => {
            if (!await isBrowserSupported()) {
                navigateWithClient("/unsupported-browser");
            }
        })();
    }, []);

    return (
        <RouteComponent {...componentProps} />
    );
}

export default BrowserSupportChecker;
import Client from "./_client";
import { Session } from "utils";

export const postFeedback = async (rating, comment) => {
  let url = "/feedback";

  const payload = { user: Session.userId, rating };

  if (comment) payload.comment = comment;

  const result = await new Client({
    path: url,
    payload,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to send feedback. Please try again."
    );
  }
  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};

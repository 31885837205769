import React from 'react';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import { signOut } from 'services';
import Session from 'utils/Session';
import CenterFlexBox from './CenterFlexBox';

const useStyles = makeStyles({
    myAccount: {
        textTransform: "none",
        textDecoration: "none",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#000000",
    },
    menu: {
        '& .MuiPaper-root': {
            background: "#FFFFFF",
            boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            '& .MuiMenu-list': {
                padding: '6px',
            },
            '& .MuiMenuItem-root': {
                fontFamily: 'Inter',
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000000",
            },
        },
    }
});

export default function ProfileMenu() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        signOut();
        navigate('/');
    };

    return (
        <>
            <CenterFlexBox>
                <PersonIcon
                    color='default'
                    fontSize='large'
                    style={{
                        border: '2px solid #47B5FF',
                        borderRadius: '50%'
                    }}
                />
                <Tooltip title="Account settings" >
                    <Button
                        disableRipple
                        onClick={handleClick}
                        size="large"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        style={{ textTransform: 'none', padding: "8px 12px", }}
                        color='default'
                        endIcon={<KeyboardArrowDownIcon color='inherit' />}
                    >
                        {Session?.user?.name?.split(" ")?.[0]}
                    </Button>
                </Tooltip>
            </CenterFlexBox>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                className={classes.menu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: 0,
                            marginRight: 12,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 12,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem onClick={handleLogout}>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}

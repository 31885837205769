import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const StyledMenu = styled((props) => (
    <Menu elevation={0} {...props} />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: "#FFF",
        backgroundColor: "#001929",
        boxShadow: 'rgb(0 0 0) 0px -6px 8px 0px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            fontSize: 14,
            fontWeight: "normal",
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function ProductMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="default"
                sx={{
                    textTransform: "none",
                    fontSize: 15,
                    fontFamily: "Inter",
                }}
            >
                Products&nbsp; <KeyboardArrowDownIcon />
            </Button>
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                <MenuItem onClick={() => {
                    window.open(process.env.REACT_APP_INPREP_BETA_URL, "_blank");
                }}
                >
                    In-Prep
                </MenuItem>
                <MenuItem onClick={() => {
                    window.open(process.env.REACT_APP_MOCK_URL, "_blank");
                }}>
                    TOEFL & IELTS
                </MenuItem>
            </StyledMenu>
        </div>
    );
}

import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const sx = {
  animationContainer: {
    height: '100%', position: 'relative', display: 'flex', flexDirection: 'column',
    overflow: 'hidden',  transition: 'all 0.5s ease-in-out'
  },
  progressBox: {
    position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',
  },
  progress: {
    color: '#49B6FF', borderWidth: '2px', transition: 'all 1s ease-in-out',
  },
  progressText: {
    top: 0, left: 0, bottom: 0, right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  movingBoxes: {
    width: '100%', maxHeight: '100px', display: 'flex', overflow: 'hidden'
  },
  box: {
    display: 'flex', width: '100%', alignSelf: 'end',
    transition: 'all 1s ease-in',
  }
}

const boxes = [
  { color: '#ECE8FE', h1: '72px', h2: '140px' },
  { color: '#FEECE8', h1: '115px', h2: '65px' },
  { color: '#EAFCF4', h1: '50px', h2: '130px' },
  { color: '#FEECE8', h1: '80px', h2: '125px' },
  { color: '#ECE8FE', h1: '155px', h2: '80px' },
  { color: '#FEECE8', h1: '102px', h2: '180px' },
  { color: '#EAFCF4', h1: '70px', h2: '160px' },
]

function CircularProgressWithLabel({ ...props }) {
  const [size, setSize] = React.useState(props.size ?? 150)
  const [thickness, setThickness] = React.useState(2)
  const radius = (size - thickness) / 2;

  React.useEffect(() => {
    if (props.value <= 0) {
      setSize(140);
    } else {
      setSize(120);
    }
  }, [props]);

  return (
    <Box sx={{ ...sx.progressBox, marginTop: props.value > 0 ? '70px' : '0px' }}>
      <Box position="relative" display="inline-flex">
        <svg width={size} height={size} style={{ transition: 'all 1s ease-in-out', }}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#d3d3d3"
            strokeWidth={thickness}
            strokeDasharray="2, 5"
            style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%", ...sx.progress }}
          />
        </svg>
        <CircularProgress variant="determinate"
          {...props} style={{ ...sx.progress, position: 'absolute', height: size, width: size }}
          thickness={1}
        />
      </Box>
      <Box sx={{ ...sx.progressText }}>
        <Typography variant="caption" component="div" color="#49B6FF" fontSize="14px">
          {
            props.value >= 90 ?
              <span>Get Ready</span>
              :
              props.value === 0
                ? <span>All the Best!</span>
                : <span style={{ fontSize: '20px' }}>{Math.round(props.value / 25)}</span>
          }
        </Typography>
      </Box>
    </Box>
  );
}

const CircularProgressCountDown = ({ proceedToTest = console.log, onDialogClose = console.log, }) => {
  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    let delay = 500;
    let intervalDuration = 1200;

    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 0 ? 100 : prevProgress - 25));

      setTimeout(() => {
      }, delay);
    }, intervalDuration + delay);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (progress < 0) {
      onDialogClose();
      proceedToTest();
    }
  }, [progress])

  return (
    <Box height={400}>
    <Box sx={{ ...sx.animationContainer, justifyContent: progress > 0 ? 'space-between' : 'center' }}>
      <CircularProgressWithLabel value={progress} />
      {
        progress > 0 ?
          <Box sx={sx.movingBoxes}>
            {
              boxes.map((data, i) => {
                return (
                  <Box sx={sx.box} key={i}
                    backgroundColor={data.color}
                    minHeight={Math.round(progress / 25) % 2 === 0 ? data.h1 : data.h2}
                  >
                  </Box>
                )
              })
            }
          </Box>
          : null
      }
    </Box>
    </Box>
  )
}

export default CircularProgressCountDown;